<script>
import { wpService } from "@/services/wp";
import Cleave from "cleave.js";

const fieldPhone = {
  render(el) {
    return el("input", {
      attrs: {
        class: "input",
        type: "tel",
        placeholder: "Número de contacto",
        value: this.value,
      },
    });
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cleave: null,
    };
  },
  mounted() {
    this.cleave = new Cleave(this.$el, {
      numericOnly: true,
      delimiters: ["(", ")", " ", "-"],
      blocks: [0, 3, 0, 3, 4],
      onValueChanged: this.onInput,
    });
  },
  beforeDestroy() {
    this.cleave.destroy();
    this.cleave = null;
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.rawValue);
    },
  },
  watch: {
    value(newValue) {
      this.cleave.setRawValue(newValue);
    },
  },
};

export default {
  components: {
    fieldPhone,
  },

  props: {
    ltg_id: Number,
    variant: String,
  },

  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  },

  computed: {
    validEmail() {
      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(this.email);
    },
    errors() {
      let errors = [];
      if (!this.name.length) errors.push("Ingrese su nombre.");
      if (!this.email.length) {
        errors.push("Ingrese su correo electrónico.");
      } else if (!this.validEmail) {
        errors.push("Correo electrónico inválido.");
      }
      if (!this.phone.length) {
        errors.push("Ingrese un teléfono de contacto.");
      } else if (this.phone.length < 10) {
        errors.push("Teléfono de contacto incompleto.");
      }
      if (!this.message.length) errors.push("Escriba un mensaje.");
      return errors;
    },
    ready() {
      return this.errors.length === 0;
    },
  },

  methods: {
    close() {
      this.$parent.close();
    },
    async submit() {
      if (!this.ready) {
        let message = "";
        for (let index = 0; index < this.errors.length; index++) {
          message += `<li>${this.errors[index]}</li>`;
        }
        message = `<ul>${message}</ul>`;
        this.$buefy.dialog.alert({
          title: "Errores en formulario",
          // message: `<div class="content">${message}</div>`
          message,
        });
        return;
      }
      const loader = this.$buefy.loading.open();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("listing_inquiry");
      let data = {
        token,
        ltg_id: this.ltg_id,
        variant: this.variant,
        name: this.name,
        phone: this.phone,
        email: this.email,
        message: this.message,
      };
      // console.log("submit", data);
      wpService.listings
        .inquiry(data)
        .then(() => {
          this.$parent.close();
        })
        .catch(err => {
          this.$buefy.dialog.alert("El mensaje no pudo ser enviado.");
          console.error(err);
        })
        .finally(() => {
          loader.close();
        });
    },
  },

  watch: {
    $route() {
      this.close();
    },
  },
};
</script>

<template>
  <div>
    <h3 class="title">Contactar anunciante</h3>
    <form @submit.prevent="submit">
      <b-field>
        <b-input placeholder="Nombre y Apellido" v-model="name" />
      </b-field>
      <div class="field">
        <fieldPhone v-model.lazy="phone" />
        <!-- <b-input placeholder="Número de contacto" v-model="phone" type="tel" /> -->
      </div>
      <b-field>
        <b-input
          placeholder="Correo electrónico"
          v-model="email"
          type="email"
        />
      </b-field>
      <div class="field">
        <textarea
          class="textarea"
          rows="4"
          placeholder="Mensaje"
          v-model="message"
        />
      </div>
      <b-button type="is-primary" native-type="submit">
        <span>Enviar mensaje</span>
      </b-button>
    </form>
  </div>
</template>
