<template>
  <section class="sitioShare content">
    <h3 class="title is-4">Share</h3>
    <div class="option">
      <!-- <b-icon icon="facebook-f" pack="fab" /> -->
      <svgFacebook />
      Facebook
    </div>
    <div class="option">
      <!-- <b-icon icon="twitter" pack="fab" /> -->
      <svgTwitter />
      Twitter
    </div>
    <div class="option">
      <!-- <b-icon icon="facebook-messenger" pack="fab" /> -->
      <svgMessenger />
      Messenger
    </div>
    <div class="option">
      <!-- <b-icon icon="envelope" /> -->
      <svgEnvelope />
      Email
    </div>
    <div class="option">
      <!-- <b-icon icon="clone" /> -->
      <svgCopy />
      Copiar enlace
    </div>
    <div class="option">
      <!-- <b-icon icon="whatsapp" pack="fab" /> -->
      <svgWhatsapp />
      WhatsApp
    </div>
    <!-- <div class="option">
      <svgMessage />
      SMS
    </div> -->
  </section>
</template>

<script>
import svgFacebook from "@/svg/facebook";
import svgMessenger from "@/svg/facebook-messenger";
import svgTwitter from "@/svg/twitter";
import svgEnvelope from "@/svg/envelope";
import svgCopy from "@/svg/copy";
import svgWhatsapp from "@/svg/whatsapp";
// import svgMessage from "@/svg/message";

export default {
  name: "SShare",

  components: {
    // svgMessage,
    svgFacebook,
    svgMessenger,
    svgTwitter,
    svgEnvelope,
    svgCopy,
    svgWhatsapp,
  },

  watch: {
    $route() {
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.sitioShare {
  .option {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    border-top: 1px solid #eee;
    svg {
      display: block;
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5rem;
    }
  }
}
</style>
