<script>
import { wpService } from "@/services/wp";
import Intersect from "vue-intersect";
import imageSwapper from "@/components/image-swapper.vue";
import { mapGetters } from "vuex";
import contactPublisher from "@/components/contact-publisher.vue";
import share from "@/components/share.vue";
export default {
  name: "singleListing",

  metaInfo() {
    const title = this.title;
    return {
      title,
    };
  },

  components: {
    Intersect,
    imageSwapper,
  },

  props: {
    prefill: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      ltg_id: Number(this.$route.params.ltg_id),
      loading: false,
      data: this.prefill,
      intersectingContent: false,
      intersectingChip: false,
    };
  },

  created() {
    if (!this.ltg_id) this.exit();
    if (!this.data) {
      wpService.listings
        .getSingle(this.ltg_id)
        .then(response => {
          this.data = response;
        })
        .catch(err => {
          console.log(err);
          this.exit();
        });
    }
    // document.documentElement.classList.add("is-clipped");
    document.body.classList.add("singleListing--open");
  },

  mounted() {
    document.addEventListener("keyup", this.escapeEvent);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escapeEvent);
    document.body.classList.remove("singleListing--open");
    // document.documentElement.classList.remove("is-clipped");
  },

  computed: {
    variant() {
      return this.$route.query.variant || null;
    },
    title() {
      return this.data
        ? `${this.category.label} en ${this.location.loc_name_short}`
        : undefined;
    },
    amenities() {
      return this.get_amenities(this.data.ltg_amenities);
    },
    ltg_cond() {
      return this.get_condition(this.data.ltg_cond);
    },
    category() {
      return this.get_category(this.data.ltg_cat);
    },
    location() {
      return this.get_location(this.data.ltg_lctn);
    },
    ready() {
      return !!this.data;
    },
    ...mapGetters("config", [
      "temp_trades",
      "get_category",
      "get_location",
      "get_condition",
      "get_amenities",
    ]),
  },

  methods: {
    goBack() {
      if (this.$routerHistory.hasPrevious()) {
        this.$router.go(-1);
      } else {
        this.exit();
      }
    },
    exit() {
      this.$emit("exit");
    },
    escapeEvent(event) {
      if (event.which == 27) this.exit();
    },
    contact() {
      this.$sitio.modal.open({
        parent: this.$root,
        component: contactPublisher,
        hasModalCard: true,
        props: {
          ltg_id: this.data.ltg_id,
          variant: this.variant,
        },
      });
    },
    share() {
      if (navigator.share) {
        // const loadingComponent = this.$buefy.loading;
        // loadingComponent.open();
        navigator
          .share({
            title: "Sitio",
            text: "Check out this amazing property!",
            url: window.location.href,
          })
          .then(something => {
            console.log(something);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            // loadingComponent.close();
          });
      } else {
        // console.log("Navigator doesn't support sharing");
        // this.$buefy.modal.open({
        //   parent: this
        // });
        this.$sitio.modal.open({
          parent: this,
          component: share,
        });
      }
    },
    isOdd(num) {
      return num % 2;
    },
    oddOrEven(x) {
      return x & 1 ? "odd" : "even";
    },
    isFullTile(index) {
      return (
        index === 0 ||
        (this.isOdd(index) && this.data.ltg_images.length - 1 === index) ||
        this.data.ltg_images.length < 3
      );
    },
  },
};
</script>

<template>
  <portal to="listing">
    <b-loading v-if="!data" :active="true" />
    <div v-else class="singleListing">
      <div class="modal-background" @click="exit"></div>
      <div class="listing__grid">
        <mq-layout v-if="data" mq="md+" class="column-if-wide">
          <div class="media-stream">
            <div
              v-for="(image, index) in data.ltg_images"
              :key="index"
              class="media-stream-tile"
              :class="[oddOrEven(index), { isFullTile: isFullTile(index) }]"
            >
              <img
                class="size-holder"
                src="/img/holders/sizeholder-landscape.png"
              />
              <picture class="media-stream-photo">
                <source
                  v-if="index == 0 || isFullTile(index)"
                  :srcset="image.srcset"
                  sizes="(min-width: 84em) 800px, 50vw"
                />
                <source
                  v-else
                  :srcset="image.srcset"
                  sizes="(min-width: 84em) 400px, (min-width: 75em) 30vw, (min-width: 64em) 20vw, (min-width: 56.25em) 25vw, 50vw"
                />
                <img :src="image.src" />
              </picture>
            </div>
          </div>
        </mq-layout>
        <div class="listing__body">
          <div
            class="listing__bar"
            :class="{ intersectingContent, intersectingChip }"
          >
            <div class="bar_bg"></div>
            <div class="barMenu">
              <b-button @click="goBack">
                <b-icon icon="arrow-left" />
              </b-button>
              <div class="bar_chip">
                <h2 class="da--title">
                  {{ category.label }} en {{ location.loc_name_short }}
                </h2>
                <div class="box__details" v-if="false">
                  <div class="da--ltg_room">
                    <span class="da__value">{{ data.ltg_room || "--" }}</span>
                    <span class="da__label" v-if="data.ltg_room > 1"
                      >Habs.</span
                    >
                    <span class="da__label" v-else>Hab.</span>
                  </div>
                  <div class="da--ltg_bath">
                    <span class="da__value">{{ data.ltg_bath || "--" }}</span>
                    <span class="da__label" v-if="data.ltg_bath > 1"
                      >Baños</span
                    >
                    <span class="da__label" v-else>Baño</span>
                  </div>
                  <div class="da--ltg_park">
                    <span class="da__value">{{ data.ltg_park || "--" }}</span>
                    <span class="da__label" v-if="data.ltg_park > 1"
                      >Parqueos</span
                    >
                    <span class="da__label" v-else>Parqueo</span>
                  </div>
                </div>
              </div>
              <div class="tools">
                <b-button @click="share">
                  <b-icon icon="share-alt" />
                </b-button>
                <b-button class="like">
                  <b-icon icon="heart" />
                </b-button>
              </div>
            </div>
          </div>
          <div class="listing__carousel" v-if="$mq == 'sm' && data">
            <imageSwapper
              :images="data.ltg_images"
              proportions="landscape"
              sizes="(min-width:40em) 40em, 90vw"
            />
          </div>
          <Intersect
            @enter="intersectingContent = false"
            @leave="intersectingContent = true"
            rootMargin="-64px 0px 0px 0px"
          >
            <div class="interceptor"></div>
          </Intersect>
          <div class="listing__content" v-if="data">
            <div class="ibox ibox--chip">
              <h2 class="da--title">
                {{ category.label }} en {{ location.loc_name_short }}
              </h2>
              <Intersect
                @enter="intersectingChip = false"
                @leave="intersectingChip = true"
                rootMargin="-124px 0px 0px 0px"
              >
                <div class="interceptor"></div>
              </Intersect>
              <div class="box__details">
                <div class="da--ltg_room">
                  <span class="da__value">{{ data.ltg_room || "--" }}</span>
                  <span class="da__label" v-if="data.ltg_room > 1">Habs.</span>
                  <span class="da__label" v-else>Hab.</span>
                </div>
                <div class="da--ltg_bath">
                  <span class="da__value">{{ data.ltg_bath || "--" }}</span>
                  <span class="da__label" v-if="data.ltg_bath > 1">Baños</span>
                  <span class="da__label" v-else>Baño</span>
                </div>
                <div class="da--ltg_park">
                  <span class="da__value">{{ data.ltg_park || "--" }}</span>
                  <span class="da__label" v-if="data.ltg_park > 1"
                    >Parqueos</span
                  >
                  <span class="da__label" v-else>Parqueo</span>
                </div>
              </div>
            </div>
            <div class="ibox ibox--trades">
              <template v-for="(meta, trade) in temp_trades">
                <div
                  v-if="data[trade]"
                  :key="trade"
                  class="da--trade price"
                  :class="{ variant: variant == trade }"
                >
                  <div class="da__label price__label">{{ meta.label }}:</div>
                  <div class="da__value price__value">
                    <span class="price__currency">USD</span>
                    ${{ data[trade] | formatMoney(0) }}
                  </div>
                </div>
              </template>
            </div>
            <div class="ibox ibox--location">
              <h3 class="title">Ubicación</h3>
              <b-message type="is-primary">{{
                location.loc_name_long
              }}</b-message>
            </div>
            <div class="ibox ibox--author" v-if="data.chip_org">
              <h3 class="title">
                <span>Inmobiliaria</span>
              </h3>
              <div class="author">
                <div class="avatar">
                  <img
                    v-if="!data.chip_org.org_avatar"
                    src="@/assets/avatar-square.svg"
                  />
                  <img
                    v-else
                    :src="data.chip_org.org_avatar.src"
                    :srcset="data.chip_org.org_avatar.srcset"
                    sizes="160"
                  />
                </div>
                <div class="data">
                  <div class="publisher__name">
                    <span class="da__label">Nombre: </span>
                    <span class="da__value">{{ data.chip_org.org_name }}</span>
                  </div>
                  <div class="publisher__phone" v-if="data.chip_org.org_phone">
                    <span class="da__label">Tel. fijo: </span>
                    <span class="da__value">
                      <a :href="'tel:+1' + data.chip_org.org_phone">{{
                        data.chip_org.org_phone | formatPhone
                      }}</a>
                    </span>
                  </div>
                  <div v-if="data.chip_org.org_address">
                    <span class="da__value">{{
                      data.chip_org.org_address
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <b-button
                  v-if="data.chip_org.org_slug"
                  tag="router-link"
                  type="is-link"
                  :to="{
                    name: 'organization-single',
                    params: { org_slug: data.chip_org.org_slug },
                  }"
                  >Visitar el perfil</b-button
                >
              </div>
            </div>
            <div class="ibox ibox--author">
              <h3 class="title">
                <span v-if="data.chip_org">Agente</span>
                <span v-else>Anunciante</span>
              </h3>
              <div class="author">
                <div class="avatar">
                  <img
                    v-if="!data.chip_author.user_avatar"
                    src="@/assets/avatar-square.svg"
                  />
                  <img
                    v-else
                    :src="data.chip_author.user_avatar.src"
                    :srcset="data.chip_author.user_avatar.srcset"
                    sizes="160"
                  />
                </div>
                <div class="data">
                  <div
                    class="publisher__name"
                    v-if="data.chip_author.user_name_full"
                  >
                    <span class="da__label">Nombre: </span>
                    <span class="da__value">{{
                      data.chip_author.user_name_full
                    }}</span>
                  </div>
                  <div
                    class="publisher__phone"
                    v-if="data.chip_author.user_phone_fixed"
                  >
                    <span class="da__label">Tel. fijo: </span>
                    <span class="da__value">
                      <a :href="'tel:+1' + data.chip_author.user_phone_fixed">{{
                        data.chip_author.user_phone_fixed | formatPhone
                      }}</a>
                    </span>
                  </div>
                  <div
                    class="publisher__phone"
                    v-if="data.chip_author.user_phone_mobile"
                  >
                    <span class="da__label">Tel. móvil: </span>
                    <span class="da__value">
                      <a
                        :href="'tel:+1' + data.chip_author.user_phone_mobile"
                        >{{
                          data.chip_author.user_phone_mobile | formatPhone
                        }}</a
                      >
                    </span>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <b-button
                  v-if="data.chip_author.user_id"
                  tag="router-link"
                  type="is-link"
                  :to="{
                    name: 'user',
                    params: { user_id: data.chip_author.user_id },
                  }"
                  >Visitar el perfil</b-button
                >
                <b-button type="is-link" @click="contact()"
                  >Contactar anunciante</b-button
                >
              </div>
            </div>
            <div class="ibox ibox--details">
              <h3 class="title">Detalles</h3>
              <div class="databox da--category" v-if="category">
                <span class="da__label">Tipo: </span>
                <span class="da__value" v-text="category.label" />
              </div>
              <div class="databox da--ltg_cond" v-if="ltg_cond">
                <span class="da__label">Condición: </span>
                <span class="da__value" v-text="ltg_cond.label" />
              </div>
              <div class="databox da--ltg_built" v-if="data.ltg_built">
                <span class="da__label">Construcción: </span>
                <span class="da__value">{{ data.ltg_built || 0 }} m²</span>
              </div>
              <div class="databox da--ltg_mts" v-if="data.ltg_mts">
                <span class="da__label">Terreno: </span>
                <span class="da__value">{{ data.ltg_mts || 0 }} m²</span>
              </div>
              <div class="databox da--ltg_level" v-if="data.ltg_level">
                <span class="da__label">Nivel/Piso: </span>
                <span class="da__value">{{ data.ltg_level || "N/D" }}</span>
              </div>
              <div class="databox da--ltg_buildable">
                <span class="da__label">Edificable: </span>
                <span class="da__value">{{
                  data.ltg_buildable ? "Si" : "No"
                }}</span>
              </div>
              <div class="databox da--ltg_year" v-if="data.ltg_year">
                <span class="da__label">Año Construcción: </span>
                <span class="da__value">{{ data.ltg_year || "N/D" }}</span>
              </div>
              <div class="databox da--ltg_room" v-if="data.ltg_room">
                <span class="da__label">Habitaciones: </span>
                <span class="da__value">{{ data.ltg_room || 0 }}</span>
              </div>
              <div class="databox da--ltg_bath" v-if="data.ltg_bath">
                <span class="da__label">Baños: </span>
                <span class="da__value">{{ data.ltg_bath || 0 }}</span>
              </div>
              <div class="databox da--ltg_park" v-if="data.ltg_park">
                <span class="da__label">Parqueos: </span>
                <span class="da__value">{{ data.ltg_park || 0 }}</span>
              </div>
            </div>
            <div class="ibox ibox--ltg_desc">
              <h3 class="title">Descripción</h3>
              <div
                v-if="data.ltg_desc"
                v-html="data.ltg_desc.replace(/\r?\n/g, '<br />')"
              ></div>
            </div>
            <div class="ibox ibox--amenities" v-if="amenities.length">
              <h3 class="title">Comodidades</h3>
              <ul>
                <li
                  v-for="amenity in amenities"
                  :key="amenity.amenity_id"
                  class="amenity"
                >
                  <span class="icon has-text-primary">
                    <!-- <i class="fas fa-circle" /> -->
                    <i class="fas fa-check" />
                  </span>
                  <span class="da__value">{{ amenity.amenity_label_es }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<style lang="scss">
@media (max-width: 39.9375em) {
  body.singleListing--open .app__view {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.singleListing {
  --content-padding-x: 1.5rem;
  position: relative;
  width: 100%;
  z-index: calc(var(--sitio-nav-index) + 1);

  .listing__grid {
    position: relative;
    background-color: #fff;
    min-height: 100vh;
  }

  .listing__bar {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    .bar_bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
    }
    .bar_bg,
    .bar_chip {
      transition: 200ms all ease-in-out;
    }
    &:not(.intersectingChip) {
      .bar_chip {
        opacity: 0;
      }
    }
    .barMenu {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4rem;
      padding: 0rem 0.75rem;
      .bar_chip {
        flex: 1 1 auto;
        padding: 0 0.75rem;
        overflow: hidden;
        .da__value,
        .da__label {
          white-space: nowrap;
        }
        .da--title {
          font-size: 1.125rem;
          line-height: 1.2;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .box__details {
          font-size: 0.8125em;
        }
      }
      .tools {
        display: flex;
        > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
      button,
      .button {
        border: none;
        width: 2rem;
        height: 2rem;
      }
      .like i {
        font-weight: normal;
      }
    }
  }

  .listing__body {
    position: relative;
  }

  .listing__carousel {
    position: relative;
    z-index: 2;
  }

  .listing__content {
    padding-bottom: 2rem;
    background-color: #fff;
  }

  .ibox {
    margin: 0 var(--content-padding-x, 0);
    padding: 1.25rem 0;
    background-color: #fff;
    .title {
      // font-size: 1.25rem;
      // font-size: 1.375rem;
      // font-size: 1rem;
      // font-size: 0.9375rem;
      font-size: 0.875rem;
      // color: #000;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 900;
    }
  }

  .box__details {
    display: flex;
    // line-height: 1;
    > :not(:first-child) {
      margin-left: 0.5rem;
      border-left: 1px solid #ccc;
      padding-left: 0.5rem;
    }
    .da__value {
      font-weight: bold;
    }
    .da__label:before {
      display: inline;
      content: "\00a0";
    }
  }

  .ibox--chip {
    .da--title {
      // font-size: 2.25rem;
      font-size: 2rem;
      // font-size: 1.75rem;
      line-height: 1.2;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .ibox--trades {
    padding-top: 0;
    .da--trade {
      justify-content: space-between;
      padding: 0.5rem 0;
      border-bottom: 1px solid #eee;
      .da__label {
        opacity: 0.8;
      }
    }
    .variant {
      .da__label {
        border-bottom: 1px solid $sitio1;
      }
    }
  }

  .ibox--details {
    .databox {
      display: flex;
      border-top: 1px solid #e2e2e2;
      &:last-child {
        border-bottom: 1px solid #e2e2e2;
      }
      .da__label,
      .da__value {
        padding: 0.5rem;
      }
      .da__label {
        width: 45%;
        flex: 0 0 45%;
        opacity: 0.9;
        background: #f1f1f4;
        // color: #54545a;
        font-size: 0.875rem;
        // font-weight: bold;
        padding-right: 1rem;
        text-transform: uppercase;
      }
      .da__value {
        font-weight: 500;
      }
    }
  }

  .ibox--amenities {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      // li:before {
      //   display: block;
      // }
      li {
        padding-left: 1rem;
        position: relative;
      }
      .da__value {
        font-size: 0.875rem;
      }
      .icon {
        position: absolute;
        left: 0;
        // font-size: 0.375rem;
        font-size: 0.625rem;
        padding: 0;
        width: auto;
      }
    }
  }

  .ibox--author {
    .author {
      position: relative;
      margin-bottom: 1.75rem;
      display: flex;
      align-items: flex-start;
      width: 100%;
      > :nth-child(2) {
        margin-left: 1rem;
      }
      .avatar {
        text-align: right;
        flex: 0 0 5rem;
        border-radius: 0.25rem;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
      }
      .data {
        flex: 1 0 auto;
        font-size: 0.9375rem;
        font-size: 0.875rem;
      }
    }
    .publisher__name {
      .da__label {
        display: none;
      }
      .da__value {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
    .publisher__phone {
      .da__value {
        font-weight: 500;
      }
    }
  }

  .media-stream {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.25rem;
    .size-holder {
      width: 100%;
    }
    .media-stream-tile {
      position: relative;
      .media-stream-photo {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
    .media-stream-tile.isFullTile {
      grid-column: span 2;
      // .media-stream-photo {
      //   height: auto;
      // }
    }
    .media-stream-tile {
      @media (max-width: 56.1875em) {
        grid-column: span 2;
      }
      .media-stream-photo {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 39.9375em) {
  .singleListing {
    .listing__grid {
      .column-if-wide {
        display: none;
      }
    }

    .listing__bar {
      position: fixed;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          to top,
          transparent 0%,
          rgba(0, 0, 0, 0.25) 100%
        );
      }
      &:not(.intersectingContent) {
        .bar_bg {
          opacity: 0;
        }
      }
    }

    .listing__carousel /deep/ {
      .slider-controls .icon {
        display: none;
      }
      .indicators {
        padding-bottom: 1rem;
      }
    }

    .listing__content {
      // border-top-left-radius: 1.5rem;
      // border-top-right-radius: 1.5rem;
      // margin-top: -1.5rem;
      overflow: hidden;
      position: relative;
      z-index: 2;
    }
  }
}

@media (min-width: 40em) {
  .singleListing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    .listing__grid {
      width: 100%;
      height: 100%;
      max-width: 84rem;
      display: flex;
      // justify-content: space-between;
      justify-content: flex-end;
      --content-width: 24rem;
      > :nth-child(1) {
        flex: 1 1 100%;
      }
      > :nth-child(2) {
        border-left: 1px solid #eee;
        flex: 0 0 25rem;
      }
      .column-if-wide,
      .listing__body {
        overflow-y: auto;
        scroll-behavior: smooth;
        will-change: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}

@media (min-width: 64em) {
  .singleListing {
    .listing__grid {
      margin: 0 auto;
      width: calc(100vw - 10rem);
    }
  }
}

@media (min-width: 90em) {
  .singleListing {
    .listing__grid {
      .listing__body {
        flex: 0 0 28rem;
      }
    }
  }
}
</style>
