import { render, staticRenderFns } from "./facebook-messenger.vue?vue&type=template&id=37bb1ba4&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports